$(function () {
  let windowWidth = $(window).width();
  let windowHeight = $(window).height();

  // スムーススクロール
  $('a[href^="#"]').on('click', function () {
    let adjust = $('header').height();
    let speed = 400;
    let href = $(this).attr('href');
    let target = $(href == '#' || href == '' ? 'html' : href);
    let position = target.offset().top - adjust;
    $('body,html').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });

  // spMenu
  $('.l-header__spMenu').on('click', function () {
    $(this).toggleClass('is-open');
    $('.l-header__right').toggleClass('is-open');
    if ($(this).hasClass('is-open')) {
      $('.l-header__spMenu__en').text('CLOSE');
      $('html').addClass('scroll-fixed');
    } else {
      $('.l-header__spMenu__en').text('MENU');
      $('html').removeClass('scroll-fixed');
    }
  });
  $('.hasSub__btn').on('click', function () {
    $(this).toggleClass('is-open');
    $(this).parent().find('.js-subMenu').slideToggle();
  });

  // header
  if (windowWidth > 767) {
    let startPos = 0,
      winScrollTop = 0;
    $(window).on('scroll', function () {
      winScrollTop = $(this).scrollTop();
      if (winScrollTop >= startPos) {
        $('.l-header').addClass('is-hide');
      } else {
        $('.l-header').removeClass('is-hide');
      }
      startPos = winScrollTop;
    });
  }

  // side
  if (windowWidth < 768) {
    $(window).on('scroll', function () {
      let header = $('header').height();
      let footer = $('footer').height();
      let mv1 = $('.c-mv1').height();
      let fromTop = $(window).scrollTop();
      let docHeight = $(document).height();
      if (
        fromTop > header + mv1 &&
        fromTop + windowHeight < docHeight - footer
      ) {
        $('.c-banner').addClass('is-show');
      } else {
        $('.c-banner').removeClass('is-show');
      }
    });
  }
});
